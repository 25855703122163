import React from "react";
import client1 from '../../assets/images/clients/ncycdo.png';
import client2 from '../../assets/images/clients/sifen.png';
import client3 from '../../assets/images/clients/glitter.png';
import  './Clients.css'
function OurClients() {
  return (
    <div className="clients">
      <div className="content-heder">
        <div className="content-back">our client</div>
        <div className="content-header-motto">
          DON'T LIMIT <span>YOURSELF</span>{" "}
        </div>
      </div>

      <div className="clients-container">
        <div className="clients-card">
          <img src={client1} alt="" />
        </div>
        <div className="clients-card">
          <img src={client2} alt="" />
        </div>
        <div className="clients-card">
          <img src={client3} alt="" />
        </div>
       
      </div>
    </div>
  );
}

export default OurClients;
