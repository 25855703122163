import React, { useState } from "react";
import './contact.css'
function ContactCard({ contact }) {
    const [info, setInfo] = useState(contact.info)
    console.log(info)
    return (
        <div className="contact-card">
            <div className="contact-icon">
                {contact.icon}
            </div>
            <div className="contact-info-cont">

                <div className="contact-info-header">{contact.name}</div>
                <div className="contact-info-sub">
                    {contact.subInfo}
                </div>
                <div className="contact-info-links">
                    {
                        info.map(info => {
                            return (
                                <a href={contact.link} target="_blank" rel="noreferrer">{info}</a>
                            )
                        })

                    }

                </div>
            </div>
        </div>
    );
}

export default ContactCard;
