import React from "react";
import "./Discover.css";
import dama from "../../assets/images/dama.png";
function Discover() {
  return (
    <div className="discover" id="explore">
      <div className="discover-container">
        <div className="aloha">Hello</div>
        <div className="discover-text">
          <span>Elevate</span>  your business
        </div>
        <p>
          <span className="glow"> Ready to take your business to the next level?</span> IX-IT and Marketing
          Solutions is your one-stop shop for all your technology and marketing
          needs. We empower businesses like yours to thrive in today's
          competitive landscape with innovative solutions that drive growth,
          success, and a stronger online presence.
        </p>

        <img src={dama} alt="" className="discover-corner" />

        <a href="#contact" className="discover-btn">Discover now +</a>
      </div>
    </div>
  );
}

export default Discover;
