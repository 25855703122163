import React, { useState } from "react";
import Discover from "../Componets/Discover/Discover";
import ServiceContainer from "../Componets/Service/ServiceContainer";
import Projects from "../Componets/Projects/Project";
import Skill from "../Componets/Skill/Skill";
import Consultant from "../Componets/Consultant/Consultant";
import Hero from "../Componets/hero/Hero";
import Cat from "../Componets/CAT/Cat";
import ContactInfo from "../Componets/ContactInfo/ContactInfo";
import Footer from "../Componets/Footer/Footer";
import OurClients from "../Componets/Clients/Clinets";

function Home({exploreHandler, exploreStatus}) {
 
  return (
    <div>
      <Hero explore={exploreHandler} />

      {exploreStatus && (
        <>
          <Discover />
          <ServiceContainer />
          {/* <Service /> */}
          <Projects />
          <Cat />
          {/* <Skill /> */}
          <OurClients/>
          <Consultant />
          <ContactInfo />
          <Footer />
        </>
      )}
    </div>
  );
}

export default Home;
