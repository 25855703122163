import React from 'react'
import './cat.css'
function Cat() {
    return (
        <div className='cat'>
            <div className="cat-section">
                <div className="cat-hero">
                Take Control of Your IT & Marketing. Get Started with IX-IT Today
                </div>
                <div className="cat-sub">
                Discover our innovative solutions that drive growth and efficiency.
                </div>
                <div className="cat-btn-section">
                    <div className="cat-btn cat-btn-connect">Connect</div>
                    <div className="cat-btn cat-btn-request">Request</div>
                </div>
            </div>

            <div className="cat-img">
                <img src="https://th.bing.com/th/id/OIF.J29zeIPiXJ2krJ2u6ZHOlg?rs=1&pid=ImgDetMain" alt="" />
            </div>
        </div>
    )
}

export default Cat
