import { useState } from "react";
import Consultant from "./Componets/Consultant/Consultant";
import Discover from "./Componets/Discover/Discover";
import Hero from "./Componets/hero/Hero";
import Navbar from "./Componets/Navbar/Navbar";
import Service from "./Componets/Service/Service";
import Skill from "./Componets/Skill/Skill";
import Footer from "./Componets/Footer/Footer";
import Projects from "./Componets/Projects/Project";
import ServiceContainer from "./Componets/Service/ServiceContainer";
import { Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";

function App() {
  const [exploreStatus, setExploreStatus] = useState(false);

  const exploreHandler = () => {
    window.scroll(1000, 0);
    setExploreStatus(true);
  };
  return (
    <div className="App">
      <Navbar exploreHandler={exploreHandler} exploreStatus={exploreStatus} />
      <Routes>
        <Route path="/" element={<Home exploreHandler={exploreHandler} exploreStatus={exploreStatus} />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
}

export default App;
