const ncycdo = require("./assets/images/projects/ncycdo.png");
const ncycdo2 = require("./assets/images/projects/ncycdo2.png");
const sifen = require("./assets/images/projects/sifen.png");
const sifen2 = require("./assets/images/projects/sifen2.png");
const netracker = require("./assets/images/projects/netracker.png");
const wecanchat = require("./assets/images/projects/wecanchat.png");
export const service = [
  {
    hardware: [
      "Maintenance",
      "Computer",
      "Networking",
      "cctv camera",
      "hardware design",
    ],
    software: [
      "software",
      "MOBILE APP",
      "Web Application",
      "E-COMMERCE",
      "& so much more",
    ],
    graphics: [
      "GRAPHIC",
      "UI / UX DESIGN",
      "WEB DESIGN",
      "VIDEOGRAPHY",
      "SOUND EFFECTS",
    ],
    marketing: [
      "marketing",
      "GOOGLE ADS",
      "BRANDING",
      "SOCIAL MEDIA",
      "Event Management",
    ],
  },
];

export const serviceCategory = [
  {
    title: "Hardware",
    icon: (
      <svg
        className="icon"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="currentColor"
        class="bi bi-motherboard icon"
        viewBox="0 0 16 16"
      >
        <path d="M11.5 2a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m2 0a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5m-10 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zM5 3a1 1 0 0 0-1 1h-.5a.5.5 0 0 0 0 1H4v1h-.5a.5.5 0 0 0 0 1H4a1 1 0 0 0 1 1v.5a.5.5 0 0 0 1 0V8h1v.5a.5.5 0 0 0 1 0V8a1 1 0 0 0 1-1h.5a.5.5 0 0 0 0-1H9V5h.5a.5.5 0 0 0 0-1H9a1 1 0 0 0-1-1v-.5a.5.5 0 0 0-1 0V3H6v-.5a.5.5 0 0 0-1 0zm0 1h3v3H5zm6.5 7a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z" />
        <path d="M1 2a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-2H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 9H1V8H.5a.5.5 0 0 1-.5-.5v-1A.5.5 0 0 1 .5 6H1V5H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 2zm1 11a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1z" />
      </svg>
    ),
  },
  {
    title: "Software",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-braces icon"
        viewBox="0 0 16 16"
      >
        <path d="M2.114 8.063V7.9c1.005-.102 1.497-.615 1.497-1.6V4.503c0-1.094.39-1.538 1.354-1.538h.273V2h-.376C3.25 2 2.49 2.759 2.49 4.352v1.524c0 1.094-.376 1.456-1.49 1.456v1.299c1.114 0 1.49.362 1.49 1.456v1.524c0 1.593.759 2.352 2.372 2.352h.376v-.964h-.273c-.964 0-1.354-.444-1.354-1.538V9.663c0-.984-.492-1.497-1.497-1.6M13.886 7.9v.163c-1.005.103-1.497.616-1.497 1.6v1.798c0 1.094-.39 1.538-1.354 1.538h-.273v.964h.376c1.613 0 2.372-.759 2.372-2.352v-1.524c0-1.094.376-1.456 1.49-1.456V7.332c-1.114 0-1.49-.362-1.49-1.456V4.352C13.51 2.759 12.75 2 11.138 2h-.376v.964h.273c.964 0 1.354.444 1.354 1.538V6.3c0 .984.492 1.497 1.497 1.6" />
      </svg>
    ),
  },
  {
    title: "Graphics",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-layers icon"
        viewBox="0 0 16 16"
      >
        <path d="M8.235 1.559a.5.5 0 0 0-.47 0l-7.5 4a.5.5 0 0 0 0 .882L3.188 8 .264 9.559a.5.5 0 0 0 0 .882l7.5 4a.5.5 0 0 0 .47 0l7.5-4a.5.5 0 0 0 0-.882L12.813 8l2.922-1.559a.5.5 0 0 0 0-.882zm3.515 7.008L14.438 10 8 13.433 1.562 10 4.25 8.567l3.515 1.874a.5.5 0 0 0 .47 0zM8 9.433 1.562 6 8 2.567 14.438 6z" />
      </svg>
    ),
  },
  {
    title: "Marketing",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-badge-tm-fill icon"
        viewBox="0 0 16 16"
      >
        <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm3.295 3.995V11H4.104V5.995h-1.7V5H7v.994H5.295zM8.692 7.01V11H7.633V5.001h1.209l1.71 3.894h.039l1.71-3.894H13.5V11h-1.072V7.01h-.057l-1.42 3.239h-.773L8.75 7.008h-.058z" />
      </svg>
    ),
  },
];

export const projectData = [
  {
    name: "we can chat",
    image: wecanchat,
    url: "https://wecanchat-d53ea.web.app/",
  },
  {
    name: "Net Tracker",
    image: netracker,
    url: "https://nettracker-50f24.web.app/",
  },
  {
    name: "NCYCDO",
    image: ncycdo,
    url: "https://www.ncycdo.com/",
  },
  {
    name: "sifen cpd center",
    image: sifen,
    url: "http://sifencpd.com/",
  },
];

export const ContactData = [
  {
    name: "Email",
    link: "mailto:ixsolutions01@gmail.com",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-envelope"
        viewBox="0 0 16 16"
      >
        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
      </svg>
    ),
    info: ["ixsolutions01@gmail.com"],
    subInfo:
      "Connect with us to learn more about our services and how we can help you.",
  },
  {
    name: "Live chat",
    link: "https://t.me/IX_Technologies",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-chat-left-text"
        viewBox="0 0 16 16"
      >
        <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
        <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6m0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5" />
      </svg>
    ),
    info: ["Start a conversion"],
    subInfo:
      "Call us to discuss your technology needs and find the best solutions.",
  },
  {
    name: "Phone",
    link: "tel:+251-910-068-212",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-telephone"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
        />
      </svg>
    ),
    info: ["+251-910-068-212", "+251-922-543-579", "+251-962-018-602"],
    subInfo:
      "Visit our office for a personalized consultation and expert advice.",
  },
  {
    name: "Office",
    link: "https://www.google.com/maps/place/Minch+market+center(%E1%88%9D%E1%8A%95%E1%8C%AD+%E1%8B%A8%E1%8C%88%E1%89%A0%E1%8B%AB+%E1%88%9B%E1%8B%95%E1%8A%A8%E1%88%8D+)+Adama/@8.5466881,39.2678696,17z/data=!3m1!4b1!4m14!1m7!3m6!1s0x164b1f726c2c08f5:0x7c211a60f0486a50!2zTWluY2ggbWFya2V0IGNlbnRlcijhiJ3hipXhjK0g4Yuo4YyI4Ymg4YurIOGIm-GLleGKqOGIjSApIEFkYW1h!8m2!3d8.5466881!4d39.2704445!16s%2Fg%2F11v417szg0!3m5!1s0x164b1f726c2c08f5:0x7c211a60f0486a50!8m2!3d8.5466881!4d39.2704445!16s%2Fg%2F11v417szg0?entry=ttu&utm_medium=s2email&shorturl=1",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-geo-alt"
        viewBox="0 0 16 16"
      >
        <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
        <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
      </svg>
    ),
    info: ["Adama - mebrat hayle minch yegebeya maekel, biro G+5-14,"],
    subInfo: "Send us a message and we'll get back to you promptly.",
  },
];
