import React, { useState } from "react";
import "./Hero.css";
import { socialMedia } from "../../data/data";
import Explore from "../Explore/Explore";
function Hero({ explore }) {
  return (
    <div className="hero-section">
      <div className="horizontal-line"></div>

      <div className="hero-section-text">
        <p>we Code Your Dreams to Shape Your Future</p>
        <p>INNOVATIVE</p>
      </div>
      <Explore setFun={explore} /> 
      <div className="hero-footer">
        <div className="play-anim-container">
          <svg className="play-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814z" />
          </svg>
        </div>
        <div className="social-media-footer">

          <p>Let's start</p>
          <ul className="social-media">
            {socialMedia.map((socialMedia) => {
              return (
                <li>
                  <a href={socialMedia.link} target="_blank">{socialMedia.name}</a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Hero;
