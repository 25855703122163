import React from 'react'
import './Expo.css'
function Explore({setFun}) {
    return (
        <a href='#explore' className='expo-container' onClick={()=>{setFun();}}>
            <div className="expo creative">
                Let's creative
            </div>
            <div className="expo-center">OR</div>
            <div className="expo explore">
                Explore now
            </div>
        </a>
    )
}

export default Explore
