import React from "react";
import logo from "../../assets/images/logo.png";

import "./Footer.css";
import { socialMedia } from "../../data/data";
function Footer() {
  return (
    <footer>
      <div class="footer-content footer">
        <div className="about-ix pro">
          <div className="logo">
            <img src={logo} alt="" className="footer-logo" />
          </div>
          <div className="about-ix-moot">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum
            earum nesciunt accusantium repellendus nostrum ipsa odio iste qui,
          </div>
        </div>
        <div class="pro">
          <div class="list-title">Links</div>
          <ul id="productsList">
            <li>Home</li>
            <li>Service</li>
            <li>Contact us</li>
            <li>Support</li>
            <li>FAQ</li>
          </ul>
        </div>
        <div class="pro">
          <div class="list-title">Blog</div>
          <ul id="productsList">
            <li>Resource</li>
            <li>Partner</li>
            <li>Event</li>
          </ul>
        </div>
        <div class="pro">
          <div class="list-title">Useful links</div>
          <ul id="productsList">
            <li>Home</li>
            <li>Service</li>
            <li>About</li>
            <li>Contact us</li>
          </ul>
        </div>
        <div className="pro">
          <div className="subscribe-container">
            <div className="sub-title list-title">Subscribe</div>
            <div className="sub-title-sub">Join our mailing list for the latest updates and promotions.</div>
            <div className="sub-form">
              <input type="text" name="" id="" />
              <button className="sub-btn" type="submit">subscribe</button>
            </div>
            <div className="sub-footer">By subscribing, you agree to our Privacy Policy and consent to receive updates from us.</div>
          </div>

          {/* <div class="list-title">Visit</div>
            <a href="https://www.google.com/maps/place/Minch+market+center(%E1%88%9D%E1%8A%95%E1%8C%AD+%E1%8B%A8%E1%8C%88%E1%89%A0%E1%8B%AB+%E1%88%9B%E1%8B%95%E1%8A%A8%E1%88%8D+)+Adama/@8.5466881,39.2678696,17z/data=!3m1!4b1!4m14!1m7!3m6!1s0x164b1f726c2c08f5:0x7c211a60f0486a50!2zTWluY2ggbWFya2V0IGNlbnRlcijhiJ3hipXhjK0g4Yuo4YyI4Ymg4YurIOGIm-GLleGKqOGIjSApIEFkYW1h!8m2!3d8.5466881!4d39.2704445!16s%2Fg%2F11v417szg0!3m5!1s0x164b1f726c2c08f5:0x7c211a60f0486a50!8m2!3d8.5466881!4d39.2704445!16s%2Fg%2F11v417szg0?entry=ttu&utm_medium=s2email&shorturl=1" target="_blank" className="visit">
              <span className="foot-sub-title"> Address:</span> Adama - mebrat hayle minch yegebeya maekel, biro G+5-14,
            </a>
            <a className="visit" href="mailto:ixsolutions01@gmail.com">
              <span className="foot-sub-title">Email:</span>  ixsolutions01@gmail.com
            </a>
            <a href="#" className="visit phone-container">
              <span className="foot-sub-title phone">CALL US:</span>

              <div className="phone-div">

                <a href="tel:+251-910-068-212" className="phones">+251-910-068-212</a>
                <a href="tel:+251-922-543-579" className="phones">+251-922-543-579</a>
                <a href="tel:+251-962-018-602" className="phones">+251-962-018-602</a>
              </div>
            </a> */}
        </div>
      </div>
      <div class="footer-developer">
        <div class="developer-copy">&#9400; 2023 IX IT and Marketing solution. All rights reserved.</div>

        <div className="social-account">
          {
            socialMedia.map((socialMedia) => {
              return (
                <a href={socialMedia.link} target="_blank">{socialMedia.icon}</a>
              );
            })
          }

        </div>
      </div>
    </footer>
  );
}

export default Footer;

{
  /* <div className="footer">
      <div className="about-ix">
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <div className="about-ix-moot">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum earum
          nesciunt accusantium repellendus nostrum ipsa odio iste qui,
        </div>
      </div>
      <div className="footer-links">
        <p className="links-header">Links</p>
        <ul>
          <li> Home</li>
          <li>Home</li>
          <li>Home</li>
          <li>Home</li>
        </ul>
      </div>
      <div className="address">
        <div className="links-header">Address</div>
        <ul>
          <li>address lab technology</li>
        </ul>
      </div>
    </div> */
}
