import React from 'react'
import ContactCard from './ContactCard'
import './contact.css'
import { ContactData } from '../../data'
function ContactInfo() {
    return (
        <div className="contact" id='contact'>
            <div className="content-heder">
                <div className="content-back">Contact</div>
                <div className="content-header-motto">
                us for any <span>questions</span> 
                </div>
            </div>

            <div className='contact-info'>
                {
                    ContactData.map((contact, key) => {
                        return (
                            <ContactCard contact={contact} key={key} />
                        )
                    })
                }

            </div>
        </div>
    )
}

export default ContactInfo
