import React from "react";
import "./Navbar.css";
import logo from "../../assets/images/logo.png";

function Navbar({exploreHandler}) {
  const toggleMenu = () => {
    document.querySelector(".navbar").classList.toggle("active");
  }
  const removeMenu = () => {
    document.querySelector(".navbar").classList.remove("active");
  }
  return (
    <nav className="navbar ">
      <a href="/" className="logo">
        <img src={logo} alt="" />
        <span className="logo-text">IX-IT and Marketing Solution</span>
        <span className="logo-motto">We build your feature</span>
      </a>
      <div className="links" onClick={removeMenu}>
        <ul onClick={e=>e.stopPropagation()}>
          <li className="active">
            <a href="/">Home</a> 
          </li>
          <li onClick={()=>{ removeMenu(); exploreHandler()}}>
            <a href="#service">Service</a>
          </li>
          <li onClick={()=>{ removeMenu(); exploreHandler()}}>
            <a href="#projects">Projects</a>
          </li>
          <li onClick={()=>{ removeMenu(); exploreHandler()}}>
            <a href="#contact">Contact us</a>
          </li>
        </ul>
      </div>

      <div className="nav-btn">
        {/* <div className="order">make order</div> */}
        <div className="menu-container" onClick={toggleMenu}>
          <div className="menu"></div>
          <div className="menu"></div>
          <div className="menu"></div>
          <div className="menu"></div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
