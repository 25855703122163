import React from "react";

function ServiceCategoryCard({ icon, title, count }) {
  return (
      <div className={`service-card card-strategy card-strategy-${count}`}>
        <div className="service-icon">
         {icon}
        </div>
        <div className="service-text">{title} </div>
        <div className="service-number">0{count}.</div>
    </div>
  );
}

export default ServiceCategoryCard;
