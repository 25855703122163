import React from "react";
import pro1 from "../../assets/images/projects/sims.png";
import './Projects.css';
import { projectData } from "../../data";
function ProjectsCardContainer() {
  return (
    <div className="projects-card-container" id="projects">
    {
      projectData.map((project)=>{
        return(
          <a href={project.url} target="_blank" className="project-card">
            <img src={project.image} alt="" />
          </a>
        )
      })
    }

    </div>
  );
}

export default ProjectsCardContainer;
